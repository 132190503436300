import {Howl} from 'howler';

/**
 * Player class.
 * Includes all methods for playing, skipping, updating the display, etc.
 */
 var Player = function(playlist) {
    this.playlist = playlist;
 };

Player.prototype = {

    toggle: function(idx) {
        var self = this;
        if ($('[data-idx="'+idx+'"]').find('div.pl-wait').css('display') == 'block') {
          return;
        }
        if ($('[data-idx="'+idx+'"]').find('button.pl-play').css('display') == 'block') {
            self.play(idx);
        }
        else {
            self.pause(idx);
        }
    },

    play: function(idx) {
      var self = this;
      var sound;
    
      var data = self.playlist[idx];

      // Pause all others
      for (let i in self.playlist) {
        if (self.playlist[i].howl && i != idx) { 
            self.pause(i);
        }
      }

      // If we already loaded this track, use the current one.
      // Otherwise, setup and load a new Howl.
      if (data.howl) {
        sound = data.howl;
      } else {
        sound = data.howl = new Howl({
          src: ["https://podcasts.kibeo.ch/" + data.file],
          html5: true, // Force to HTML5 so that the audio can stream in (best for large files).
          onplay: function() {
            // Display the duration.
            var obj = $('[data-idx="'+idx+'"]');
            obj.parents('div.pl-player').find('span.pl-duration').html(self.formatTime(Math.round(sound.duration())));
  
            // Start updating the progress of the track.
            requestAnimationFrame(self.step.bind(self,idx));

            obj.find('button.pl-pause').css('display','block');
            obj.find('div.pl-loading').css('display','none');
          },
          onload: function() {
            $('[data-idx="'+idx+'"]').find('div.pl-loading').css('display','none');
          },
          onend: function() {
          },
          onpause: function() {
          },
          onstop: function() {
          },
          onseek: function() {
            // Start updating the progress of the track.
            requestAnimationFrame(self.step.bind(self,idx));
          }
        });
      }
  
      // Begin playing the sound.
      sound.play();
      // Tracking
      if (typeof _paq !== 'undefined') {  _paq.push(['trackEvent', 'PodcastPlayer', 'play', data.file])}

      var obj = $('[data-idx="'+idx+'"]');
  
      // Show the pause button.
      if (sound.state() === 'loaded') {
        obj.find('button.pl-play').css('display','none');
        obj.find('button.pl-pause').css('display','block');
      } else {
        obj.find('div.pl-loading').css('display','block');
        obj.find('button.pl-play').css('display','none');
        obj.find('button.pl-pause').css('display','none');
      }
    },
  
    /**
     * Pause the currently playing track.
     */
    pause: function(idx) {
      var self = this;
  
      // Get the Howl we want to manipulate.
      var sound = self.playlist[idx].howl;
  
      // Tracking
      if (sound.playing() && (typeof _paq !== 'undefined')) {_paq.push(['trackEvent', 'PodcastPlayer', 'pause', self.playlist[idx].file]) }
      // Pause the sound.
      sound.pause();

      // Show the play button.
      var obj = $('[data-idx="'+idx+'"]');
      obj.find('button.pl-play').css('display','block');
      obj.find('button.pl-pause').css('display','none');
      obj.find('div.pl-loading').css('display','none');
    },
  
    /**
     * Seek to a new position in the currently playing track.
     * @param  {Number} per Percentage through the song to skip.
     */
    seek: function(idx, per) {
      var self = this;

      if ($('[data-idx="'+idx+'"]').find('div.pl-wait').css('display') == 'block') {
        return;
      }
  
      // Get the Howl we want to manipulate.
      var sound = self.playlist[idx].howl;

      if (sound && sound.playing() && sound.state()==='loaded') {
        sound.seek(sound.duration() * per);
      } else if (sound && sound.state()==='loading') {
        // Do nothing
      } else {
        self.play(idx);
      }
    },

    skip: function(idx, delta) {
      var self = this;
      
      if ($('[data-idx="'+idx+'"]').find('div.pl-wait').css('display') == 'block') {
        return;
      }

      // Get the Howl we want to manipulate.
      var sound = self.playlist[idx].howl;

      if (sound && sound.state()==='loaded') {
        sound.seek(sound.seek() + delta);
      }
    },
  
    /**
     * The step called within requestAnimationFrame to update the playback position.
     */
    step: function(idx) {
      var self = this;
  
      // Get the Howl we want to manipulate.
      var sound = self.playlist[idx].howl;
  
      // Determine our current seek position.
      var seek = sound.seek() || 0;
      var obj = $('[data-idx="'+idx+'"]');
      obj.parents('div.pl-player').find('span.pl-timer').html(self.formatTime(Math.round(seek)));
      obj.parent().find('div.pl-progress').css('width',(((seek / sound.duration()) * 100) || 0) + '%');
  
      // If the sound is still playing, continue stepping.
      if (sound.playing()) {
        requestAnimationFrame(self.step.bind(self,idx));
      }
    },
  
    /**
     * Format the time from seconds to M:SS.
     * @param  {Number} secs Seconds to format.
     * @return {String}      Formatted time.
     */
    formatTime: function(secs) {
      var minutes = Math.floor(secs / 60) || 0;
      var seconds = (secs - minutes * 60) || 0;
  
      return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }
};

$(function() {
    var files = [];
    $("div.audio").each(function(index) {
        var data = $( this ).data();
        files.push({file: data['audiofile'], howl: null});
        $(this).find('div.playbutton').attr('data-idx', index);
    });

    // Setup our new audio player class and pass it the playlist.
    var player = new Player(files);

    $("div.pl-toggle").on('click', function() {
        player.toggle($(this).data('idx'))
    });

    $("div.pl-waveform").on('click', function(event) {
        player.seek($(this).parent().find('div.pl-toggle').data('idx'),event.offsetX / event.currentTarget.clientWidth);
    });

    $("div.pl-skip").on('click', function() {
        player.skip($(this).parents('div.pl-player').find('div.pl-toggle').data('idx'),$(this).data('length'));
  });

});

